import axios from 'axios'
import {
    Message
} from 'element-ui'

const service = axios.create({
    // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    //测试站
    // baseURL:'https://sjtest.yundianai.com',
    baseURL:'https://sj.yundianai.com',
    //正式站
    // baseURL:'http://170.106.148.125:8081',
    //小贝本地
    // baseURL:'http://192.168.80.211',
    timeout: 100000 // request timeout
})

service.interceptors.request.use(
    config => {
        if (localStorage.getItem("userinfo") !== null && localStorage.getItem("userinfo") !== 'null' && localStorage.getItem("userinfo") !== '' && localStorage.getItem("userinfo") !== undefined && localStorage.getItem("userinfo") !== 'undefined') {
            let currentUser = JSON.parse(localStorage.getItem("userinfo"))
        if (currentUser&&currentUser.token) {
            // console.log(currentUser.token)
            config.headers.Token=currentUser.token;
            // config.headers['content-type'] = 'application/x-www-form-urlencoded';
        }}
        return config
    },
    error => {
        console.log(error)
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        return response
    },
    error => {
        console.log(error)
        const res = error.response.data
        const status = error.response.status
        // 接口返回5开头状况处理
        if (status >= 500) {
            Message.error('服务繁忙请稍后再试')
            // 接口返回4开头状况处理
        } else if (status >= 400) {
            // 接口返回401处理
            if (res.status === 401) {
                Message.warning("您的账号登录已失效, 请重新登录")
            } else {
                Message.error("未知错误")
                console.error(error)
            }
        }
        return Promise.resolve(error)
    }
)
export default service